<script setup lang="ts">import { ref as _ref } from 'vue';

import { categories, type Category } from '@consumer/logic/v2/header'
import { useThrottleFn } from '@vueuse/core'
import { categoryBarFocused } from '@consumer/modules/v2/layout/Overlay.vue'
import CategoryButton from './CategoryBar/Button.vue'
import CategoryDesktopDropdown from './CategoryBar/DesktopDropdown.vue'

let category = _ref<Category | null>(null)

let barFocused = _ref(false)

const showCategory = useThrottleFn((cat: Category) => {
  category.value = cat
  categoryBarFocused.value = true
}, 100)

const hideCategory = () => {
  category.value = null
  categoryBarFocused.value = false
}

const onMouseEnter = (cat: Category) => {
  setTimeout(() => {
    if (barFocused.value) {
      showCategory(cat)
    }
  }, category.value ? 0 : 200)
}

const onMouseLeave = () => {
  if (!barFocused.value) { hideCategory() }
}

const onMouseEnterBar = () => {
  barFocused.value = true
}
const onMouseLeaveBar = () => {
  barFocused.value = false
  hideCategory()
}
</script>

<template>
  <div class="w-full flex items-center justify-center">
    <div
      class="flex-row gap-0 flex items-center justify-center pt-[12px] relative"
      @mouseenter="onMouseEnterBar"
      @mouseleave="onMouseLeaveBar"
    >
      <CategoryButton
        v-for="cat, key in categories"
        :key="key"
        :category="cat"
        @mouseenter="onMouseEnter(cat)"
        @mouseleave="onMouseLeave"
        @click.prevent="showCategory(cat)"
      />
      <CategoryDesktopDropdown v-if="category" :category="category"/>
    </div>
  </div>
</template>
