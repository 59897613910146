<script lang="ts" setup>
import {
  faUser,
  faArrowRightFromBracket,
  faGift,
  faCircleInfo,
  faHeadset,
  faGiftCard,
  faBriefcase,
} from '@fortawesome/pro-light-svg-icons'
import type { CurrentUser } from '@consumer/types/serializers'
// import { openHowGiftlyWorksModal } from '@consumer/services/modals'
import MenuItem from './MenuItem.vue'

const currentUser = inject<CurrentUser | null>('currentUser')

defineModel<boolean>('open')

// const howItWorksModal = () => {
//   open.value = false
//   openHowGiftlyWorksModal()
// }
</script>

<template>
  <div
    :class="[
      'py-[16px] lg:p-[12px] space-y-3',
      'border-b-1 border-b-solid border-b-v2-grey-cool'
    ]"
  >
    <MenuItem
      :label="currentUser ? `My Account` : `Sign Up`"
      :icon="faUser"
      iconColor="#00A2F1"
      iconBgColor="#F3F9FF"
      :url="currentUser ? `/my-account/purchases` : `/signup`"
    />
    <MenuItem
      :label="currentUser ? `Logout` : `Login`"
      :icon="faArrowRightFromBracket"
      iconColor="#00A2F1"
      iconBgColor="#F3F9FF"
      :url="currentUser ? `/signout` : `/signin`"
    />
  </div>
  <div
    :class="[
      'py-[16px] lg:p-[12px] space-y-[12px]',
    ]"
  >
    <MenuItem
      label="Browse"
      :icon="faGift"
      iconColor="#61BF49"
      iconBgColor="#E4FEDD"
      url="/browse"
    />
    <MenuItem
      label="How it works"
      :icon="faCircleInfo"
      iconColor="#00A2F1"
      iconBgColor="#F3F9FF"
      url="/how-it-works"
    />
    <MenuItem
      label="Help"
      :icon="faHeadset"
      iconColor="#A675C3"
      iconBgColor="#F7F0FA"
      url="/support"
    />
    <MenuItem
      label="Redeem"
      :icon="faGiftCard"
      iconColor="#FFBC10"
      iconBgColor="#FFF6DE"
      url="/redeem"
    />
    <MenuItem
      label="For Business"
      :icon="faBriefcase"
      iconColor="#AE3B00"
      iconBgColor="#FFDFCE"
      url="/corp"
    />
  </div>
</template>
