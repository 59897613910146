
<script setup lang="ts">
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons'

import InputField from './InputField.vue'

withDefaults(defineProps<{
  name: string
  icon?: any
  placeholder?: string
}>(), {  })

const model = defineModel<string>()

const emit = defineEmits(['focus', 'blur', 'input'])

const inputFieldRef = ref<InstanceType<typeof InputField>>()

defineExpose({
  focus: () => inputFieldRef.value?.focus(),
  blur: () => inputFieldRef.value?.blur(),
})
</script>

<template>
  <InputField
    ref="inputFieldRef"
    v-model="model"
    :name="name"
    :icon="icon"
    :placeholder="placeholder"
    class="header-v2-search-input-field-mobile"
    @focus="emit('focus', $event)"
    @blur="emit('blur', $event)"
    @input="emit('input', $event)"
  >
    <template #addonRight>
      <button
        :class="[
          'outline-transparent',
          'transition-all duration-200 ease-in-out',
          model ? 'opacity-100' : 'opacity-0'
        ]"
        @click.prevent="model = ''"
      >
        <V2Icon
          :icon="faXmarkCircle"
          size="md"
          class="text-v2-secondary"
        />
      </button>
    </template>
  </InputField>
</template>

<style lang="scss">
.header-v2-search-input-field-mobile {
  input {
    padding-right: 48px !important;
  }
}
</style>
