<script setup lang="ts">import { ref as _ref, toRef as _toRef } from 'vue';

import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'

import { useWindowScroll, whenever } from '@vueuse/core'

import { isDesktop } from '@consumer/utils/breakpoints'

import type { CurrentUser } from '@consumer/types/serializers'

import CategoryBar from './Header/CategoryBar.vue'
import GiftlyLogo from './Header/GiftlyLogo.vue'
import MainContainer from './Header/MainContainer.vue'
import SearchBarDesktop from './Header/SearchBarDesktop.vue'
import SearchBarMobile from './Header/SearchBarMobile.vue'
import UserMenu from './Header/UserMenu.vue'
import Overlay, { searchBarFocused } from './Overlay.vue'

defineEmits(['close'])

withDefaults(defineProps<{
  currentUser?: CurrentUser | null
}>(), {  })

let showCategoryBar = _ref(false)
whenever(() => isDesktop.value && searchBarFocused.value, () => {
  showCategoryBar.value = true
})

let showMobileSearchBar = _ref(false)
const toggleMobileSearch = () => {
  showMobileSearchBar.value = !showMobileSearchBar.value
  showCategoryBar.value = showMobileSearchBar.value
}

const __$temp_1 = (useWindowScroll()),
  y = _toRef(__$temp_1, 'y');
const scrolled = computed(() => y.value > 150)
</script>

<template>
  <div
    :class="[
      'lg:fixed top-0 w-full z-100 bg-white',
      scrolled ? 'lg:shadow-v2md' : null
    ]"
  >
    <V2Container class="!p-0 w-full">
      <MainContainer :withSearchBar="showCategoryBar">
        <div class="w-full flex items-center justify-between h-[60px]">
          <GiftlyLogo/>
          <div class="hidden lg:block">
            <SearchBarDesktop :compact="true"/>
          </div>

          <div class="flex items-center h-full gap-[8px]">
            <V2Button
              variant="secondary"
              size="small"
              :class="[
                'lg:hidden',
                '!shadow-none',
                'border-1 border-solid border-v2-grey-cool rounded-v2md',
                '!text-v2-secondary !p-[12px] !h-[44px] !w-[44px]',
                {
                  '!text-v2-active !bg-v2-blue-alice': showMobileSearchBar,
                }
              ]"
              data-track-ga="mobile_nav_search_icon_tap"
              @click="toggleMobileSearch"
            >
              <V2Icon :icon="faMagnifyingGlass" size="md"/>
            </V2Button>

            <UserMenu :currentUser="currentUser"/>
          </div>
        </div>
      </MainContainer>
      <Transition name="slide-down">
        <div v-show="showMobileSearchBar" class="px-[12px] py-[8px] lg:hidden">
          <SearchBarMobile/>
        </div>
      </Transition>
    </V2Container>
  </div>

  <div class="lg:h-[84px]"/>

  <Transition name="slide-down">
    <CategoryBar v-show="showCategoryBar"/>
  </Transition>

  <Overlay/>
</template>
