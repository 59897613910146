<script setup lang="ts">
import { faLoader, faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { Link } from '@inertiajs/vue3'

import { pageTitle } from '@consumer/logic/v2/header'

import {
  mobileSummaryOpen,
  scrollToMobileSummary,
  useCheckoutStore,
} from '@consumer/stores/checkout'
import { formatCurrency } from '@consumer/utils/currency'
import checkoutConfig from '@consumer/services/checkoutConfig'

import GiftlyLogo from './Header/GiftlyLogo.vue'
import MainContainer from './Header/MainContainer.vue'
import UserMenu from './Header/UserMenu.vue'

defineEmits(['close'])

const checkoutData = useCheckoutStore()

const onClickMobileSummaryCarousel = () => {
  if (!checkoutData.readyToCheckout) {
    mobileSummaryOpen.value = !mobileSummaryOpen.value
  }
  else {
    scrollToMobileSummary.value = true
  }
}
</script>

<template>
  <div class="w-full z-100 bg-white">
    <V2Container class="!p-0 w-full">
      <MainContainer>
        <div class="w-full flex items-center justify-between h-[60px]">
          <div class="flex flex-row items-center gap-[16px]">
            <GiftlyLogo/>
            <Link :href="pageTitle.href ?? '/'" class="no-underline group">
              <h1 class="text-[14px] leading-[140%] font-medium text-v2-secondary hover:text-v2-primary">
                {{ pageTitle.text }}
              </h1>
            </Link>
          </div>

          <div class="flex flex-row items-center gap-[8px] lg:gap-[16px]">
            <div>
              <div
                :class="[
                  'lg:hidden',
                  'h-[44px] px-[16px] py-[12px]',
                  'flex flex-row items-center gap-[8px]',
                  'border-1 border-solid border-v2-grey-cool rounded-v2md',
                  'text-v2-primary font-semibold',
                ]"
              >
                <div class="flex-1 text-right">Total:</div>
                <div v-if="checkoutConfig.loaded">{{ formatCurrency(checkoutData.total) }}</div>
                <div v-else>
                  <V2Icon :icon="faLoader" class="animate-spin"/>
                </div>
                <div class="clickable" @click="onClickMobileSummaryCarousel">
                  <V2Icon
                    :icon="!mobileSummaryOpen || checkoutData.readyToCheckout ?
                      faChevronDown :
                      faChevronUp"
                  />
                </div>
              </div>
            </div>
            <UserMenu/>
          </div>
        </div>
      </MainContainer>
    </V2Container>
  </div>
</template>
