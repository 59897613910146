<script setup lang="ts">
import type V2InputField from '@consumer/components/V2/V2InputField.vue'

const inputFieldRef = ref<InstanceType<typeof V2InputField>>()

defineExpose({
  focus: () => inputFieldRef.value?.focus(),
  blur: () => inputFieldRef.value?.blur(),
})
</script>

<template>
  <V2InputField ref="inputFieldRef" class="header-v2-search-input-field">
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope || {}"/>
    </template>
  </V2InputField>
</template>

<style lang="scss">
.header-v2-search-input-field {
  svg.v2-input-field-icon {
    height: 20px;
    width: 20px;
  }

  input {
    border: transparent;
    box-shadow: none;
    font-size: 14px;
    line-height: 140%;
    width: 100%;
  }
}
</style>
