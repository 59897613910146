<script setup lang="ts">import { toRef as _toRef } from 'vue';

import { isDesktop } from '@consumer/utils/breakpoints'

import { useWindowScroll } from '@vueuse/core'

import CategoryBar from './Header/CategoryBar.vue'
import GiftlyLogo from './Header/GiftlyLogo.vue'
import MainContainer from './Header/MainContainer.vue'
import SearchBarDesktop from './Header/SearchBarDesktop.vue'
import SearchBarMobile from './Header/SearchBarMobile.vue'
import UserMenu from './Header/UserMenu.vue'
import Overlay from './Overlay.vue'

defineEmits(['close'])

const __$temp_1 = (useWindowScroll()),
  y = _toRef(__$temp_1, 'y');
const scrolled = computed(() => y.value > 150)
</script>

<template>
  <div
    :class="[
      'fixed top-0 w-full z-100 bg-white',
      scrolled ? 'lg:shadow-v2md' : null
    ]"
  >
    <V2Container class="!p-0 w-full">
      <Transition name="slide-down">
        <MainContainer v-show="isDesktop || (!isDesktop && !scrolled)" withSearchBar>
          <div class="w-full flex items-center justify-between h-[60px]">
            <GiftlyLogo/>

            <div class="hidden lg:block">
              <SearchBarDesktop/>
            </div>

            <UserMenu/>
          </div>
        </MainContainer>
      </Transition>
      <div class="px-[12px] py-[8px] lg:hidden">
        <SearchBarMobile/>
      </div>
    </V2Container>
  </div>

  <div class="h-[127.5px] lg:h-[84px]"/>

  <CategoryBar/>

  <Overlay/>
</template>
