<script setup lang="ts">import { ref as _ref } from 'vue';

import type { CurrentUser } from '@consumer/types/serializers'

import HeaderCheckout from './HeaderCheckout.vue'
import HeaderDefault from './HeaderDefault.vue'
import HeaderModal from './HeaderModal.vue'
import HeaderProduct from './HeaderProduct.vue'
import HeaderSimple from './HeaderSimple.vue'

defineOptions({
  inheritAttrs: false,
})

withDefaults(defineProps<{
  type?: 'default' | 'product' | 'simple' | 'checkout' | 'modal'
  currentUser?: CurrentUser | null
}>(), {  })

provide('currentUser', __props.currentUser)

const headerComponent = computed(() => {
  switch (__props.type) {
    case 'checkout':
      return HeaderCheckout
    case 'modal':
      return HeaderModal
    case 'product':
      return HeaderProduct
    case 'simple':
      return HeaderSimple
    default:
      return HeaderDefault
  }
})

const headerTypeRef = _ref<InstanceType<
  typeof HeaderDefault |
  typeof HeaderModal |
  typeof HeaderCheckout |
  typeof HeaderSimple |
  typeof HeaderProduct
>>()

defineExpose({
  closeButtonEl: (headerTypeRef.value as any)?.closeButtonEl,
})

const emit = defineEmits(['close'])
</script>

<template>
  <header id="v2-header" class="bg-white flex flex-col !border-none" v-bind="$attrs">
    <component :is="headerComponent" ref="headerTypeRef" @close="emit('close')">
      <slot/>
    </component>
  </header>
</template>
