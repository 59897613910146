<script lang="ts" setup>
import type { Category } from '@consumer/logic/v2/header'

withDefaults(defineProps<{
  category: Category
}>(), {  })

const label = computed(() => __props.category.title)
const icon = computed(() => __props.category.icon)
</script>

<template>
  <button
    class="
      flex flex-col gap-3 items-center group
      text-v2-secondary active:text-v2-primary lg:hover:text-v2-primary
      outline-none
      w-[96px]
      group
    "
    data-track-ga="open-nav-categories"
  >
    <div class="w-[20px] h-[20px] lg:w-[24px] lg:h-[24px]">
      <V2Icon :icon="icon" class="!w-full !h-full"/>
    </div>
    <div
      :class="[
        'inline-block text-[12px] font-semibold pb-2 w-full whitespace-nowrap',
        'underline-2 underline-v2-active underline-offset-8',
        'group-active:underline lg:group-hover:underline',
      ]"
    >
      {{ label }}
    </div>
  </button>

</template>
