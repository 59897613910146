<script setup lang="ts">import { ref as _ref } from 'vue';

import {
  faMagnifyingGlass,
  faLocationDot,
} from '@fortawesome/pro-light-svg-icons'

import MobileModal from '@consumer/modules/v2/layout/Header/MobileModal.vue'
import type { Suggestion } from '@consumer/logic/v2/header'

import { useUrlSearchParams } from '@vueuse/core'
import { trackEvent } from '@shared/tracking'
import MobileInputField from './SearchBar/MobileInputField.vue'
import SuggestionCategory from './SearchBar/SuggestionCategory.vue'
import SuggestionCity from './SearchBar/SuggestionCity.vue'

const open = _ref(false)

const emit = defineEmits<{(e: 'close'): Promise<void>}>()

const searchParams = useUrlSearchParams()

let category = _ref<string>(searchParams.category?.toString())
let focusedCategory = _ref(false)
const categoryFieldRef = ref<InstanceType<typeof MobileInputField>>()

const onFocusCategory = () => {
  focusedCategory.value = true
  trackEvent('click', 'gc-index-category-field')
}

const selectSuggestedCategory = (suggestion: Suggestion) => {
  category.value = suggestion.label
  if (suggestion.city) {
    city.value = suggestion.city
  }
  focusedCategory.value = false
  cityFieldRef.value?.focus()
}

let city = _ref<string>(searchParams.city?.toString())
let focusedCity = _ref(false)
const cityFieldRef = ref<InstanceType<typeof MobileInputField>>()

const selectSuggestedCity = (suggestion: Suggestion) => {
  city.value = suggestion.label
  focusedCity.value = false

  if (category.value) {
    onSubmit()
  }
  else {
    categoryFieldRef.value?.focus()
  }
}

const onFocusCity = () => {
  focusedCity.value = true
  trackEvent('click', 'gc-index-city-field')
}

const onSubmit = () => {
  emit('close')

  const searchParams = new URLSearchParams()
  if (category.value) {
    searchParams.append('category', category.value)
  }
  if (city.value) {
    searchParams.append('city', city.value)
  }

  trackEvent('click', 'gc-index-search-button', 'Search')

  window.location.href = `/search?${searchParams.toString()}`
}

</script>

<template>
  <!-- Placeholder search bar opens modal on click-->
  <div
    id="header-v2-search-load-mobile"
    class="w-full clickable"
    data-track-ga="search-load-mobile"
    @click="open = true"
  >
    <V2InputField
      name="header-v2-search-placeholder"
      type="text"
      placeholder="Find the perfect gift"
      class="pointer-events-none"
      :icon="faMagnifyingGlass"
    />
  </div>

  <!-- Modal -->
  <MobileModal v-model:open="open">
    <div class="absolute inset-0 flex flex-col pt-[64px]">
      <div class="flex-1 overflow-y-auto">
        <div
          :class="[
            'bg-white flex gap-1 p-1 border-1 border-solid border-v2-grey-cool shadow-v2sm',
            'flex-col items-start rounded-xl',
            'mx-[16px]'
          ]"
        >
          <MobileInputField
            ref="categoryFieldRef"
            v-model="category"
            name="category"
            :icon="faMagnifyingGlass"
            placeholder="Business, category or interest"
            class="h-[52px]"
            enterkeyhint="next"
            @focus="onFocusCategory"
            @blur="focusedCategory = false"
            @input="focusedCategory = false"
            @keyup.enter="cityFieldRef?.focus()"
          />

          <div
            :class="[
              'w-full h-[1px] bg-v2-grey-cool',
              focusedCategory || focusedCity ? 'opacity-0' : 'opacity-100',
            ]"
          />

          <MobileInputField
            ref="cityFieldRef"
            v-model="city"
            name="city"
            :icon="faLocationDot"
            placeholder="Neighborhood, city, state or zip"
            class="h-[52px]"
            enterkeyhint="search"
            @focus="onFocusCity"
            @blur="focusedCity = false"
            @input="focusedCity = false"
            @keyup.enter="onSubmit"
          />
        </div>

        <div class="flex-1 py-[16px] mx-[16px] pb-[300px]">
          <Transition name="slide-down">
            <SuggestionCategory
              v-show="focusedCategory"
              @select="selectSuggestedCategory"
            />
          </Transition>
          <Transition name="slide-down">
            <SuggestionCity
              v-show="focusedCity"
              @select="selectSuggestedCity"
            />
          </Transition>
        </div>
      </div>

      <div class="space-y-2 border-t-1 border-t-solid border-t-v2-grey-cool px-[16px] pt-[16px]">
        <V2Button
          variant="primary"
          type="submit"
          label="Search"
          class="w-full"
          @click.prevent="onSubmit"
        />
        <button
          type="reset"
          class="w-full !h-56px font-semibold !text-[18px] text-v2-primary hover:text-v2-active"
          @click.prevent="open = false"
        >
          Cancel
        </button>
      </div>
    </div>
  </MobileModal>
</template>
