
<script lang="ts" setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'

withDefaults(defineProps<{
  icon: IconDefinition
  size?: 'sm' | 'md' | 'lg'
}>(), { size: 'sm', })

const sizeClass = computed(() => `v2-icon-${__props.size}`)
</script>

<template>
  <FontAwesomeIcon
    :icon="icon"
    :class="[
      'v2-icon',
      sizeClass,
      'fill-current stroke-current',
    ]"
    :style="{
      maxWidth: 'initial',
    }"
  />
</template>
