<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import { faXmark } from '@fortawesome/pro-light-svg-icons'

import { pageTitle } from '@consumer/logic/v2/header'

import { trackEvent } from '@shared/tracking'

import GiftlyLogo from './Header/GiftlyLogo.vue'
import MainContainer from './Header/MainContainer.vue'

defineProps<{
  hideCloseButton?: boolean
}>()

const emit = defineEmits(['close'])

const closeButtonEl = ref<HTMLButtonElement>()

const onClickClose = (e: Event) => {
  trackEvent('click', closeButtonEl.value)
  emit('close')
}

defineExpose({
  closeButtonEl,
})
</script>

<template>
  <div class="w-full z-100 bg-white">
    <V2Container class="!p-0 w-full">
      <MainContainer>
        <div class="w-full flex items-center h-[60px] relative">
          <div class="flex flex-row items-center gap-[16px]">
            <GiftlyLogo/>

            <component
              :is="pageTitle?.href ? Link : 'div'"
              :href="pageTitle?.href"
              class="no-underline group"
            >
              <h1
                :class="[
                  'text-[14px] leading-[100%] font-medium text-v2-secondary',
                  pageTitle?.href ? 'hover:text-v2-primary' : null
                ]"
              >
                {{ pageTitle?.text }}
              </h1>
            </component>
          </div>

          <slot/>

          <div
            class="flex items-center h-full absolute right-0"
          >
            <button
              ref="closeButtonEl"
              autofocus
              type="button"
              :class="[
                'outline-none ring-0 z-1001',
                'bg-white border-1 border-solid rounded-v2md',
                'text-v2-secondary border-v2-grey-cool',
                'hover:text-v2-primary hover:border-v2-secondary',
                'h-[44px] w-[44px] flex items-center justify-center',
              ]"
              @click.prevent="onClickClose"
            >
              <span class="sr-only">Close search</span>
              <V2Icon :icon="faXmark" size="md"/>
            </button>
          </div>
        </div>
      </MainContainer>
    </V2Container>
  </div>
</template>
