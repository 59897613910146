<script setup lang="ts">
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'

import { type Category } from '@consumer/logic/v2/header'
import BuyAGift from '@consumer/modules/v2/layout/Header/BuyAGift.vue'
import MobileModal from '@consumer/modules/v2/layout/Header/MobileModal.vue'

const category = defineModel<Category | null>('category')
const open = computed<boolean>({
  get: () => Boolean(category.value),
  set: (value) => {
    if (!value) {
      category.value = null
    }
  },
})
</script>

<template>
  <MobileModal v-model:open="open">
    <div v-if="category" class="px-[16px] w-full h-full">
      <div class="w-full text-16px font-semibold flex items-center justify-center gap-2">
        <V2Icon :icon="category.icon" size="md"/>
        {{ category.title }}
      </div>

      <div class="relative h-full">
        <div class="absolute inset-0 overflow-y-auto mt-[24px] mb-[16px]">
          <div v-if="category.buyAGift">
            <BuyAGift
              :label="category.buyAGift.label"
              :path="category.buyAGift.path"
              :image="category.buyAGift.image"
              class="!w-full"
            />
          </div>
          <V2List class="border-0 shadow-transparent !p-0 my-[24px]">
            <V2ListItem
              v-for="subCategory in category.links"
              :key="subCategory.name"
              as="a"
              :href="subCategory.path"
              class="w-full"
              :label="subCategory.name"
              :icon="subCategory.icon"
              :image="subCategory.image"
            >
              <template #addonRight>
                <div>
                  <V2Icon
                    :icon="faChevronRight"
                    size="sm"
                    class="text-v2-secondary"
                  />
                </div>
              </template>
            </V2ListItem>
          </V2List>
        </div>
      </div>
    </div>
  </MobileModal>
</template>
