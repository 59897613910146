<script setup lang="ts">
import V2Card from '@consumer/components/V2/V2Card.vue'

import { userMenuFocused } from '@consumer/modules/v2/layout/Overlay.vue'
import BuyAGift from './UserMenu/BuyAGift.vue'
import Menu from './UserMenu/Menu.vue'
import MenuButton from './UserMenu/MenuButton.vue'

const open = ref(false)

watchEffect(() => {
  userMenuFocused.value = open.value
})
</script>

<template>
  <div class="flex flex-row gap-5 items-center text-v2-secondary">
    <div
      class="relative"
      @mouseenter="open = true"
      @mouseleave="open = false"
      @click="open = !open"
    >
      <MenuButton/>

      <Transition name="slide-down">
        <dialog
          :open="open"
          :class="[
            'relative',
            'bg-transparent w-full',
          ]"
        >
          <div class="absolute right-0 pt-[8px] z-100">
            <V2Card class="!p-0">
              <div
                :class="[
                  'flex flex-row items-stretch',
                ]"
              >
                <div>
                  <BuyAGift class="!h-full !w-[300px] !rounded-v2md"/>
                </div>

                <div class="w-[164px]">
                  <Menu v-model:open="open"/>
                </div>
              </div>
            </V2Card>
          </div>
        </dialog>
      </Transition>
    </div>
  </div>
</template>
