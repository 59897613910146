import type CreditCard from '@consumer/types/CreditCard'
import type { GreetingCardCategory } from '@consumer/types/serializers'
import type { ShippingMethod } from '@consumer/types/GiftDeliveryData'
import { parseDate } from '@consumer/utils/date'
import type DateStr from '@consumer/types/DateStr'
import { checkout as checkoutApi, greetingCards } from '@consumer/api'

export interface GiftFeeScheduleItem {
  min: number
  max: number | null
  fee: number
}

export interface ShippingDates {
  firstclass: DateStr
  priority: DateStr
}

export interface ShippingOption {
  key: ShippingMethod
  name: string
  upgradeFee: number
  estimatedDeliveryDate: Date | null
}

export interface CheckoutConfig {
  loaded?: boolean
  savedCard?: CreditCard
  christmasDeliveryDateCutoffs?: ShippingDates
  shipping: {
    baseFee: number
    options: Array<ShippingOption>
  }
  giftFeeSchedule: GiftFeeScheduleItem[]
  greetingCards: {
    categories: GreetingCardCategory[]
  }
  configureShippingEstimates: (estimates: ShippingDates) => void
  load: (dynamicGreetingCardCategories?: boolean) => void
}

interface CheckoutConfigurationResponse {
  christmasDeliveryDateCutoffs?: ShippingDates
  shippingEstimates: ShippingDates
  giftFeeSchedule: GiftFeeScheduleItem[]
  savedCard: CreditCard
}

const checkoutConfig: CheckoutConfig = reactive({
  shipping: {
    baseFee: 5,
    options: [
      {
        key: 'firstclass',
        name: 'First-Class Mail',
        upgradeFee: 0,
        estimatedDeliveryDate: null,
      },
      {
        key: 'firstclass_track',
        name: 'First-Class Mail with Tracking',
        upgradeFee: 3,
        estimatedDeliveryDate: null,
      },
      {
        key: 'priority',
        name: 'Priority Mail with Tracking',
        upgradeFee: 7,
        estimatedDeliveryDate: null,
      },
    ],
  },
  giftFeeSchedule: [],
  greetingCards: {
    categories: [],
  },
  configureShippingEstimates: ({ firstclass: standard, priority }) => {
    const firstclassShippingEstimate = parseDate(standard, 'yyyy-MM-dd')
    const priorityShippingEstimate = parseDate(priority, 'yyyy-MM-dd')

    checkoutConfig.shipping.options[0].estimatedDeliveryDate = firstclassShippingEstimate
    checkoutConfig.shipping.options[1].estimatedDeliveryDate = firstclassShippingEstimate
    checkoutConfig.shipping.options[2].estimatedDeliveryDate = priorityShippingEstimate
  },
  load: () => {
    const greetingCardsApi = greetingCards.categories

    Promise.all([
      checkoutApi.configuration<CheckoutConfigurationResponse>(),
      greetingCardsApi<GreetingCardCategory[]>(),
    ]).then((values) => {
      const [configData, greetingCardCategories] = values
      checkoutConfig.savedCard = configData.savedCard
      checkoutConfig.giftFeeSchedule = configData.giftFeeSchedule
      checkoutConfig.configureShippingEstimates(configData.shippingEstimates)

      if (configData.christmasDeliveryDateCutoffs) {
        checkoutConfig.christmasDeliveryDateCutoffs = {
          firstclass: parseDate(configData.christmasDeliveryDateCutoffs?.firstclass, 'yyyy-MM-dd'),
          priority: parseDate(configData.christmasDeliveryDateCutoffs?.priority, 'yyyy-MM-dd'),
        }
      }
      checkoutConfig.greetingCards.categories = greetingCardCategories

      checkoutConfig.loaded = true
    })
  },
})

export default checkoutConfig
